import React from 'react';
import { Link } from 'react-router-dom';
import { NavProps } from '../types/interfaces';

import karbon_logo from '../images/Karbon_logo.svg';
import '../css/header.css';

  function Header({navLinks, category, product}: NavProps): React.ReactElement {

  const serviceNavLink = navLinks && navLinks[0].type === 'service' ? navLinks[0] : '';
  const isNotEmptyArr = Array.isArray(navLinks) && navLinks.length !== 0;

  const displayNavLinks = isNotEmptyArr 
   ? 
   navLinks.map((link, linkId) => ( 
    <span key={'link' + linkId}>
      {link.type === 'product' 
        ? (
          <Link to={link.url ? link.url : ''} state={{ product, category, serviceNavLink }} >
            {' ' + link.name + (linkId === navLinks.length - 1 ? '' : ' > ')}
            </Link>
        ) : (
          <Link to={link.url ? link.url : ''} >
            {' ' + link.name + (linkId === navLinks.length - 1 ? '' : ' > ')}
          </Link>
        )
      }
    </span>
   ))
   : null;

  return (
    <header id="header">

      <picture>
        <img src={karbon_logo} alt="Karbon logo" />
      </picture>

      <nav>
        <div className='nav-links'>
          <span>
            <Link to="/">{`Index ${displayNavLinks ? '>' : ''}`}</Link>
          </span>
          {displayNavLinks ? displayNavLinks : <></>}
        </div>
      </nav>

    </header>
  );
};

export default Header;
