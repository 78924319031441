import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/Header';
import Title from '../components/Title';
import Footer from '../components/Footer';
import Calculator from '../components/Calculator';
import data from '../data/data.json';
import { ServiceType, CategoryType, ProductType, ServiceInCalcType } from '../types/types';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCalc } from '../features/calculator/CalcContext';
import { NavLinkProps } from '../types/interfaces';
import '../css/service.css'

function Service(): React.ReactElement {
  let { id } = useParams<{id: string}>();
  const { state, dispatch } = useCalc();

  const serviceOfTheDay =
    data.services?.find((service: ServiceType) => id ? service.day === parseInt(id) : null);

  const dayOfService = serviceOfTheDay?.day || 1;

  const serviceNavLink: NavLinkProps = {
    url: `/service/${serviceOfTheDay?.day}`, 
    name: serviceOfTheDay?.short_date,
    type: 'service'
  };

  // Add, update and remove product
  const addProductOnClick = (serviceDay: number, category: CategoryType, product: ProductType, quantity: number) => {

    addProductToCalc(serviceDay, category, product, quantity);
  };

  const compareCatName = (a: CategoryType, b: CategoryType) => a.name.localeCompare(b.name)

  const substrackProductOnClick = (serviceDay: number, category: CategoryType, product: ProductType, quantity: number) => {

    updateProductQuantity(serviceDay, category.name, product, quantity);
  };

  const productQuantityCounter = (serviceDay: number, categoryName: string, productName: string): number => {

    const serviceIndex = state.services.findIndex((service) => service.day === serviceDay);

    if (serviceIndex !== -1) {

      const categoryIndex = state.services[serviceIndex].categories.findIndex((cat) => cat.name === categoryName);

      if (categoryIndex !== -1) {
        const productIndex = state.services[serviceIndex].categories[categoryIndex].products.findIndex((prod) => prod.name === productName);

        if (productIndex !== -1) {
          const productCount = state.services[serviceIndex].categories[categoryIndex].products[productIndex].quantity || 0;
          return productCount;
        } 
      }

      return 0;
    }
    return 0;
  }

  const addProductToCalc = (serviceDay: number, category: CategoryType, product: ProductType, quantity: number) => {

    const serviceIndex = state.services.findIndex((service) => service.day === serviceDay);

    if (serviceIndex !== -1) {

    const categoryIndex = state.services[serviceIndex].categories.findIndex((cat) => cat.name === category.name);
    const updatedService: ServiceInCalcType[] = [...state.services];

      if (categoryIndex !== -1) {
        const productIndex = state.services[serviceIndex].categories[categoryIndex].products.findIndex((prod) => prod.name === product.name);

        if (productIndex !== -1) {
          const oldProductQuantity = updatedService[serviceIndex].categories[categoryIndex].products[productIndex].quantity;

          if (oldProductQuantity && oldProductQuantity < 10){
            updatedService[serviceIndex].categories[categoryIndex].products[productIndex].quantity = oldProductQuantity ? oldProductQuantity + quantity : 1;
            dispatch({ type: 'UPDATE_PRODUCT_QUANTITY', updatedService });
          }

        } else {
          
          const oldProductsInCategory = updatedService[serviceIndex].categories[categoryIndex].products;
          updatedService[serviceIndex].categories[categoryIndex].products = [ ...oldProductsInCategory, {...product, quantity}];
          dispatch({ type: 'UPDATE_CATEGORY', updatedService });
        }

      } else {

        const productByCategory: CategoryType = { 
          name: category.name,
          img: category.img,
          short_name: category.short_name,
          products: [{ ...product, quantity }]
        };
        const oldCategories = updatedService[serviceIndex].categories;
        updatedService[serviceIndex].categories = [ ...oldCategories, { ...productByCategory}].sort(compareCatName);
        dispatch({ type: 'ADD_CATEGORY', updatedService });
      }
    } else {

      const productByCategoryInService: ServiceInCalcType = { 
        day: serviceDay,
        categories: [{
          name: category.name,
          img: category.img,
          short_name: category.short_name,
          products: [{ ...product, quantity }]
        }]
      };
      dispatch({ type: 'ADD_CATEGORY_BY_SERVICE', productByCategoryInService });
    }
  };

  const updateProductQuantity = (serviceDay: number, categoryName: string, product: ProductType, quantity: number) => {

    const serviceIndex = state.services.findIndex((service) => service.day === serviceDay);

    if (serviceIndex !== -1) {
      const categoryIndex = state.services[serviceIndex].categories.findIndex((cat) => cat.name === categoryName);

      if (categoryIndex !== -1) {
        const productIndex = state.services[serviceIndex].categories[categoryIndex].products.findIndex((prod) => prod.name === product.name);
        const updatedService: ServiceInCalcType[] = [...state.services];

        if (productIndex !== -1) {
          const oldProductQuantity = updatedService[serviceIndex].categories[categoryIndex].products[productIndex].quantity;

          if (oldProductQuantity && oldProductQuantity > 1) {
            updatedService[serviceIndex].categories[categoryIndex].products[productIndex].quantity = oldProductQuantity - quantity;
            dispatch({ type: 'UPDATE_PRODUCT_QUANTITY', updatedService });

          } else if(oldProductQuantity && oldProductQuantity < 2) {
            const productName = updatedService[serviceIndex].categories[categoryIndex].products[productIndex].name;
            removeProductFromCalc(serviceDay, categoryName, productName);
          }
        }
      }
    }
  }

  const removeProductFromCalc = (serviceDay: number, categoryName: string, productName: string) => {

    const serviceIndex = state.services.findIndex((service) => service.day === serviceDay);
    const categoryIndex = state.services[serviceIndex].categories.findIndex((cat) => cat.name === categoryName);

    if (categoryIndex !== -1) {

      let updatedService: ServiceInCalcType[] = [...state.services];
      updatedService[serviceIndex].categories[categoryIndex].products = updatedService[serviceIndex].categories[categoryIndex].products.filter(prod => prod.name !== productName);

      if (updatedService[serviceIndex].categories[categoryIndex].products.length > 0) {
  
        dispatch({ type: 'REMOVE_PRODUCT', updatedService });

      } else {
  

        if (updatedService[serviceIndex].categories.length > 1) {

          const categoryName = updatedService[serviceIndex].categories[categoryIndex].name;
          updatedService[serviceIndex].categories = updatedService[serviceIndex].categories.filter(cat => cat.name !== categoryName).sort(compareCatName);
          dispatch({ type: 'REMOVE_CATEGORY', updatedService });
        } else {

          updatedService = updatedService.filter(service => service.day !== serviceDay);
          dispatch({ type: 'REMOVE_SERVICE', updatedService });
        }
      }

    }
  };

  // Display service info
  const serviceByCategories =
    serviceOfTheDay && serviceOfTheDay.categories
      ? serviceOfTheDay.categories.map((category: CategoryType, catId: number) => (

          <Accordion key={'cat' + catId}
            disableGutters
            className="service-categories-container"
          >

            {/* Category Title */}
            <AccordionSummary 
              expandIcon={<ExpandMoreIcon />}
            >
              <div className="service-categories-title-container">
                <picture className='service-categories-title-picture'>
                  <img src={require(`../images/${category.img}.png`)} alt={category.img} />
                </picture>
                <h2>{category.name}</h2>
              </div>
            </AccordionSummary>

            {/* Products list */}
            <AccordionDetails>
              <ul
              className="service-categories-ul"
            >
                {category.products.map((product: ProductType, prodId: number) => (
                  <li key={prodId}>

                    {/* Product Card */}
                    <div className='service-product-card'>

                      <div className='service-product-info-container'>
                        <p>{product.name}</p>
                        <p className='service-product-info-name-details'>{product.name_details}</p>
                      </div>

                      {/* Link */}
                      <div className='service-product-buttons-container'>
                        <Link to={'/product/' + product.slug} state={{ product, category, serviceNavLink }}>
                          Product info
                        </Link>
                      

                        {/* Select Quantity Buttons*/}
                        <div className='service-product-select-quantity'>
                          <button onClick={() => substrackProductOnClick(dayOfService, category, product, 1 )}>-</button>
                          <p>{productQuantityCounter(dayOfService, category.name, product.name)}</p>
                          <button onClick={() => addProductOnClick(dayOfService, category, product, 1 )}>+</button>
                        </div>
                      </div>
                      
                    </div>
                  </li>
                ))}
              </ul>
            </AccordionDetails>

          </Accordion>
        ))
      : null;

  return (
    <div>
      <Header navLinks={[serviceNavLink]} />
      <Title/>
      <section id="service-section">
        <p className="section-hint">Select your dishes :</p>
        {serviceByCategories ? serviceByCategories : <></>}
      </section>

      <Footer />
      <Calculator serviceNavLink={serviceNavLink} serviceDay={dayOfService} />
    </div>
  );
};

export default Service;
