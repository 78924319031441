import React, { createContext, useContext, useReducer, ReactNode, useState, useEffect } from 'react';
import { CalcStateType, ServiceInCalcType } from '../../types/types';

type CalcActionType = 
    { type: 'ADD_CATEGORY_BY_SERVICE'; productByCategoryInService: ServiceInCalcType }
    | { type: 'ADD_CATEGORY'; updatedService: ServiceInCalcType[] }
    | { type: 'UPDATE_CATEGORY'; updatedService: ServiceInCalcType[] }
    | { type: 'UPDATE_PRODUCT_QUANTITY'; updatedService: ServiceInCalcType[] }
    | { type: 'REMOVE_PRODUCT'; updatedService: ServiceInCalcType[] }
    | { type: 'REMOVE_CATEGORY'; updatedService: ServiceInCalcType[] }
    | { type: 'REMOVE_SERVICE'; updatedService: ServiceInCalcType[] };

type CalcProviderPropsType = {
    children: ReactNode;
  };

const CalcContext = createContext<{ state: CalcStateType; dispatch: React.Dispatch<CalcActionType> } | undefined>(undefined);

const calcReducer = (state: CalcStateType, action: CalcActionType): CalcStateType => {
  switch (action.type) {
    case 'ADD_CATEGORY_BY_SERVICE':
      return { ...state, services: [...state.services, action.productByCategoryInService] };
    case 'ADD_CATEGORY':
      return { ...state, services: [...action.updatedService] };
    case 'UPDATE_CATEGORY':
      return { ...state, services: [...action.updatedService] };
    case 'UPDATE_PRODUCT_QUANTITY':
      return { ...state, services: [...action.updatedService] };
    case 'REMOVE_PRODUCT':
      return { ...state, services: [...action.updatedService] };
    case 'REMOVE_CATEGORY':
      return { ...state, services: [...action.updatedService] };
      case 'REMOVE_SERVICE':
        return { services: [...action.updatedService] };
    default:
      return state;
  }
};

export const CalcProvider: React.FunctionComponent<CalcProviderPropsType> = ({ children }) => {
  const [userData, setUserData] = useState<CalcStateType>(() => {
    const jsonStoredData = localStorage.getItem('userData');

    if (jsonStoredData) {
      const storedData: CalcStateType = JSON.parse(jsonStoredData);

      if (Array.isArray(storedData.services) && storedData.services.length > 0) {
        return storedData;
      }
    }
    return { services: [] };
  });
  const [state, dispatch] = useReducer(calcReducer, userData);

  useEffect(() => {
    setUserData(state);
  }, [state]);

  useEffect(() => {
    localStorage.setItem('userData', JSON.stringify(userData));
  }, [userData]);


  return <CalcContext.Provider value={{ state, dispatch }}>{children}</CalcContext.Provider>;
};

export const useCalc = () => {
  const context = useContext(CalcContext);
  if (!context) {
    throw new Error('useCalc must be used within a CalcProvider');
  }
  return context;
};

export default CalcContext;