import React from 'react';
import ReactDOM from 'react-dom/client';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import Root from './Root';
import Product from './screens/Product';
import Service from './screens/Service';
import ErrorPage from './error-page';
import './css/general.css';
import { CalcProvider } from './features/calculator/CalcContext';

const router = createHashRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/service/:id',
    element: <Service />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/product/:name',
    element: <Product />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById('root') as Element).render(
  <CalcProvider>
    <RouterProvider router={router} />
  </CalcProvider>
);
