import React, { useState } from 'react'
import { VictoryLabel, VictoryPie } from 'victory';
import { useCalc } from '../features/calculator/CalcContext';
import { DisplayProductImpactsType, CategoryType, ProductType } from '../types/types';
import '../css/calculator.css';
import { Global } from '@emotion/react';
import { Box, SwipeableDrawer, Tab, Tabs } from '@mui/material';
import { CalculatorNavProps } from '../types/interfaces';
const drawerBleeding = 70;

function Calculator({serviceNavLink, serviceDay}: CalculatorNavProps): React.ReactElement {
  const [open, setOpen] = useState(false);
  const { state } = useCalc();

  // Switch Info container
  const [activeInfoContainer, setActiveInfoContainer] = useState<number>(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveInfoContainer(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // Toggle Category List
  const [activeList, setActiveList] = useState<number[]>([0, 1, 2])

  const toggleList = (listId: number) => {
    activeList.includes(listId) 
      ? setActiveList(activeList.filter(id => id !== listId))
      : setActiveList([...activeList, listId]);
  }

  //  Display Products Impacts
  const [displayProductImpacts, setDisplayProductImpacts] = useState<DisplayProductImpactsType>({
    products: [],
  });

  const handleDisplayProductImpacts = (product: ProductType) => {

    if (displayProductImpacts.products.some( item => item.name === product.name)) {
      setDisplayProductImpacts({ ...displayProductImpacts, products: displayProductImpacts.products.filter(item=> item.name !== product.name)});
    } else {
      setDisplayProductImpacts({ ...displayProductImpacts, products: [ ...displayProductImpacts.products, product]});
    }
  }

  // Total score and number of products
  const serviceIndex = state.services.findIndex((service) => service.day === serviceDay);
  const categoriesInCalc =  serviceIndex !== -1 ? state.services[serviceIndex].categories : [];

  const totalProductsScore: number = parseFloat(
    categoriesInCalc.map((category: CategoryType) => (
        category.products.map((product: ProductType) => (
          product.quantity ? product.quantity * product.mPt_score : 0
        ))
      ))
      .flat()
      .reduce((acc, curr) => acc + curr, 0)
      .toFixed(3)
  );

  const totalProductScoreToDivide = totalProductsScore !== 0 ? totalProductsScore : 1; // avoid infinity
      
  // THIS PART ALOWS US TO ANIMATE THE SCORE EVERYTIME IT IS UPDATED
  const [lastTotalScoreKnown, setLastTotalScoreKnown] = useState(totalProductsScore);
  if (lastTotalScoreKnown !== totalProductsScore) {
    setTimeout(() => {
      setLastTotalScoreKnown(totalProductsScore)
    }, 250)
  }


  const totalProductsNumber = categoriesInCalc.map((category) => (
    category.products.map((product) => (product.quantity ? product.quantity : 0))
  )).flat().reduce((acc, curr) => acc + curr, 0);

  // Category chart
  const categoriesScoreInPercentage = 
  categoriesInCalc.map((category: CategoryType) => ( 
    parseFloat(category.products.map((product: ProductType) => (product.quantity 
      ? (product.quantity * product.mPt_score / totalProductScoreToDivide * 100)
      : 0
      ))
      .reduce((acc, curr) => acc + curr, 0)
      .toFixed(2)
    )
  ));

  const categoriesNames = 
  categoriesInCalc.map((category: CategoryType) => ( 
    category.short_name
  ));

  const categoriesColorScale = (name: string) => {
    switch (name) {
      case 'Cold pcs':
        return "#429df1";
    
      case 'Hot pcs':
        return "#fa4f50";

      case 'Sweet pcs':
        return "#fa78b9";

      default:
        return "#429df1";
    }
  };

  const CategoriesNamesAndScores = categoriesScoreInPercentage.map((score, i) => ({
        score,
        name: categoriesNames[i],
        color: categoriesColorScale(categoriesNames[i]),
      }));

  const configCategoryChart = (categoriesScoreInPercentage: number[]) => {

    let arr = [];

      for (let i = 0; i < categoriesScoreInPercentage.length; i++) {
        arr.push({x: CategoriesNamesAndScores[i].name, y: CategoriesNamesAndScores[i].score, score: CategoriesNamesAndScores[i].score, fill: CategoriesNamesAndScores[i].color});
      }
    
    return arr;
  }

  const configCategoryColorScaleChart = (categoriesScoreInPercentage: number[]) => {

    const arr = []

    for (let i = 0; i < categoriesScoreInPercentage.length; i++) {
      arr.push(CategoriesNamesAndScores[i].color);
    }

    return arr;
  }

  const categoryChartData = configCategoryChart(categoriesScoreInPercentage);
  const categoryColorScaleChartData = configCategoryColorScaleChart(categoriesScoreInPercentage);

  // Product chart
  const productsScoreInPercentage = 
  categoriesInCalc.map((category: CategoryType) => (
    category.products.map((product: ProductType) => (product.quantity 
      ? parseFloat((product.quantity * product.mPt_score / totalProductScoreToDivide * 100).toFixed(2))
      : 0))
    ))
    .flat()
  ;

  const allProductsNames = categoriesInCalc.map((category) => (
    category.products.map((product) => (product.name))
  )).flat();

  const productNamesAndScores = productsScoreInPercentage.map((score, i) => ({
    score,
    name: allProductsNames[i],
  })).sort((a, b) => a.score - b.score);

  const productColorScale = [
    "#1f77b4", "#ff7f0e", "#2ca02c", "#d62728",
    "#9467bd", "#8c564b", "#e377c2", "#7f7f7f",
    "#bcbd22", "#17becf", "#aec7e8", "#ffbb78",
    "#98df8a", "#ff9896", "#c5b0d5"
  ];

  const configProductChart = (productsScoreInPercentage: number[]) => {

    const arr = []

    for (let i = 0; i < productsScoreInPercentage.length; i++) {
      arr.push({x: productNamesAndScores[i].name, y: productNamesAndScores[i].score, score: productNamesAndScores[i].score, fill: productColorScale[i]});
    }

    return arr;
  }

  const configProductColorScaleChart = (productsScoreInPercentage: number[], ProductColorScale: string[]) => {

    const arr = []

    for (let i = 0; i < productsScoreInPercentage.length; i++) {
      arr.push(ProductColorScale[i]);
    }

    return arr;
  }

  const productChartData = configProductChart(productsScoreInPercentage);
  const productColorScaleChartData = configProductColorScaleChart(productsScoreInPercentage, productColorScale);

  // Calculate total impacts
  const totalImpactsScoreCalc = () => {

    const productList = categoriesInCalc.flatMap(category => category.products);
    const totalImpactScoreList = [];

    for (let i = 0; i < 3; i++) {

      totalImpactScoreList
      .push(parseFloat(productList
        .flatMap(product => product.environmental_impacts ? product.environmental_impacts
        .slice(i, i+1)
        .map(impact => impact.scoring) : [])
        .reduce((acc, score) => acc + score, 0)
        .toFixed(3)
      ));
    }

    return totalImpactScoreList;
  }

  const totalImpactsComparisonScoreCalc = () => {

    const productList = categoriesInCalc.flatMap(category => category.products);
    const totalImpactScoreList = [];

    for (let i = 0; i < 2; i++) {

      totalImpactScoreList
      .push(parseFloat(productList
        .flatMap(product => product.environmental_impacts ? product.environmental_impacts
        .slice(i, i+1)
        .map(impact => product.quantity && impact.comparison_score ? impact.comparison_score * product.quantity : 0) : [])
        .reduce((acc, score) => acc + score, 0)
        .toFixed(3)
      ));
    }

    return totalImpactScoreList;
  }

  const totalImpactsScore = totalImpactsScoreCalc();
  
  const totalImpactsComparisonScore = totalImpactsComparisonScoreCalc();

  const total_impacts_labels = [
    {
      name: 'Total climate change', 
      unity: 'CO2 (eq)', 
      emoji: '🚗', 
      comparison: ' km'
    },
    {
      name: 'Total water use', 
      unity: 'm3', 
      emoji: '🚿', 
      comparison: ' showers'
    }
  ];
  
  // Total product score
  const sumToFixed = (sum: number): number | undefined  => {
    return parseFloat(sum.toFixed(3));
  }

  // Category chart
  const displayCategoryChart = categoriesScoreInPercentage.length <1 ? <></> :(
    <div className='calculator-chart-container'>
      <VictoryPie 
        data={categoryChartData}
        colorScale={categoryColorScaleChartData}
        innerRadius={140}
        width={450}
        height={550}
        labels={({ datum }) => [datum.x, `${datum.score}%`]}
        labelComponent = {
          <VictoryLabel 
            style={[
              { fontWeight: 'bold', fontSize: 26, textAlign: 'center', fill: ({ datum }) => datum.fill },
              { fontSize: 25, textAlign: 'center' },
            ]} 
          />
        }
      />
      <div className='calculator-product-chart-info-container'>
        { productNamesAndScores.map((product, prodId) => (
          <div className='calculator-product-chart-name-container' key={prodId}>
            <div className='calculator-product-chart-color-legend' style={{backgroundColor: productColorScale[prodId]}}></div>
            <p>{product.name + ' : ' + product.score + '%'}</p>
          </div>
        )) }
      </div>
      <VictoryPie 
        data={productChartData}
        colorScale={productColorScaleChartData}
        innerRadius={140}
        width={450}
        height={500}
        labelComponent = {
          <VictoryLabel 
            style={[
              { display: 'none' },
            ]} 
          />
        }
      />
    </div>
  );

  // Display product list
  const displayProductsByCategory = categoriesInCalc.map((category, catId) => (

    <div className='calculator-categories-container' key={catId}>

      {/* Category Container */}
      <div className='calculator-categories-title-container' >
        <span className='calculator-categories-title-icon'>{activeList.includes(catId) ? '-' : '+'}</span> 
        <h3 onClick={() => toggleList(catId)}>
          {category.name}
        </h3>
        <picture className='calculator-categories-title-picture'>
          <img src={require(`../images/${category.img}.png`)} alt='cold_appetizers_icon' />
        </picture>
      </div>

      {/* Category List */}
      <ul className={activeList.includes(catId) ? 'calculator-categories-ul show-list' : 'calculator-categories-ul hide-list'}>

        {category.products.map((product, prodId) => (
        
          <li key={prodId}>

            {/* Product Card */}
            <div className='calculator-product-card'>

              <div className='calculator-product-info-container'>
                <div className='calculator-info-product-name-container'>
                  <p className='calculator-info-product-name'>{product.name}</p>
                  <p className='calculator-info-product-name-details'>{product.name_details}</p>
                </div>
                <p className='info-product-quantity'>x{product.quantity}</p>
                <p className='info-product-score'>{product.quantity && sumToFixed(product.quantity * product.mPt_score)} mPt</p>
              </div>

              {/* Product Impacts */}
              <div className='calculator-product-impacts-section'>

                  <div className={displayProductImpacts.products.some( item => item.name === product.name) 
                    ? 'calculator-product-impacts-container' 
                    : 'calculator-product-impacts-container hide-product-impacts'}>

                    {product.environmental_impacts?.slice(0, 2).map((impact, impactId) => (
                      <div className='calculator-product-impact' key={impactId}>
                        <div className={`pastil product-impact-${impactId}`}></div>
                        <p className='product-impact-score'>
                        {impact.scoring + ' ' + impact.unit_name}
                        </p>
                      </div>
                    ))}

                  </div>

                  {/* Display more impact button */}
                  <p className='view-more-impact-on-click' onClick={() => handleDisplayProductImpacts(product)}>
                    {displayProductImpacts.products.some( item => item.name === product.name) 
                    ? '- hide product impacts'
                    : '+ view product impacts' }
                  </p>

              </div>

            </div>
          </li>
        ))}

      </ul>

    </div>

  ));
  const infoContainerClassnames = `${open ? 'total-info-up' : 'total-info-down'} ${lastTotalScoreKnown !== totalProductsScore ? 'bounce' : ''}`
  return (
    <div>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(85% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClick={() => !open && setOpen(true)}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            height: `calc(100% + ${drawerBleeding}px)`,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <div id="pull-anchor"/>
          <div id='calculator-container' className={open ? 'calculator-container-up' : 'calculator-container-down'}>

            {/* Category Info */}
            <div id='calculator-total-info-container' className={infoContainerClassnames}>
              <p>Total single score: {totalProductsScore} mPt ({totalProductsNumber} {totalProductsNumber > 1 ? 'products' : 'product'})</p>
            </div>

            {/* Total impacts */}
            <div id='calculator-total-impacts-container' className={open ? '' : 'hide-total-impacts'}>
              {total_impacts_labels.map((impact, impactId) => [
                <div className='calculator-total-impact' key={impactId}>
                  <p className={`total-impact-name impact-${impactId}`}>{impact.name}</p>
                  <p className='total-impact-unity'>{totalImpactsScore[impactId] + impact.unity}</p>
                  <span className='total-impact-emoji'>{impact.emoji}</span>
                  <p className='total-impact-name'>{totalImpactsComparisonScore[impactId] + impact.comparison}</p>
                </div>
              ])}
            </div>

            {/* Switch Buttons */}
            <div className={open 
              ? 'calculator-switch-buttons-container' 
              : 'calculator-switch-buttons-container hide-switch-buttons'}>

              <Tabs value={activeInfoContainer} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Products List" {...a11yProps(0)} />
                <Tab label="Charts Overview" {...a11yProps(1)} />
              </Tabs>
            </div>

            {/* Category Chart and Product List */}
            <div id='calculator-chart-and-list-container' className={open ? 'show-calculator-list' : 'hide-calculator-list'}>

              {displayProductsByCategory && activeInfoContainer === 0 ? displayProductsByCategory : <></>}

              {displayCategoryChart && activeInfoContainer === 1  ? displayCategoryChart : <></>}

              <div className='calculator-add-product-container'>
                  <p onClick={() => setOpen(false)}>+ Add a new product</p>
              </div>
            </div>
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  )
}

export default Calculator